import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import ExternalLink from '../components/partials/external'

const IndexPage = ({ data }) => {
	const home = data.strapi.homePage

	home.metadata.meta_title = 'Privacy Policy | Branding & UX Design Studio'

	return (
		<Layout page={home} metadata={home.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Headline Section */}
				<section className="py-20 lg:py-40">
					<h1 className="text-center">Privacy Policy</h1>
					<div className="md:w-8/12 mt-40 mx-auto leading-loose">
						<h4 className="font-medium">1. Introduction</h4>
						Welcome to <span class="font-medium">Opposite LLP.</span>
						<br />
						<span class="font-medium">Opposite LLP</span> (“us”, “we”, or “our”)
						operates <span class="font-medium">oppositehq.com</span>
						(hereinafter referred to as “Service”).
						<br />
						Our Privacy Policy governs your visit to{' '}
						<span class="font-medium">oppositehq.com</span>, and explains how we
						collect, safeguard and disclose information that results from your
						use of our Service.
						<br />
						We use your data to provide and improve Service. By using Service,
						you agree to the collection and use of information in accordance
						with this policy. Unless otherwise defined in this Privacy Policy,
						the terms used in this Privacy Policy have the same meanings as in
						our Terms and Conditions.
						<br />
						Our Terms and Conditions (“<span class="font-medium">Terms</span>”)
						govern all use of our Service and together with the Privacy Policy
						constitutes your agreement with us (“
						<span class="font-medium">agreement</span>”).
						<br />
						<br />
						<h4 className="font-medium">2. Definitions</h4>
						<span class="font-medium">SERVICE</span> means the oppositehq.com
						website operated by Opposite LLP.
						<br />
						<span class="font-medium">PERSONAL DATA</span> means data about a
						living individual who can be identified from those data (or from
						those and other information either in our possession or likely to
						come into our possession).
						<br />
						<span class="font-medium">USAGE DATA</span> is data collected
						automatically either generated by the use of Service or from Service
						infrastructure itself (for example, the duration of a page visit).
						<br />
						<span class="font-medium">COOKIES</span> are small files stored on
						your device (computer or mobile device).
						<br />
						<span class="font-medium">DATA CONTROLLER</span> means a natural or
						legal person who (either alone or jointly or in common with other
						persons) determines the purposes for which and the manner in which
						any personal data are, or are to be, processed. For the purpose of
						this Privacy Policy, we are a Data Controller of your data.
						<br />
						<span class="font-medium">
							DATA PROCESSORS (OR SERVICE PROVIDERS)
						</span>{' '}
						means any natural or legal person who processes the data on behalf
						of the Data Controller. We may use the services of various Service
						Providers in order to process your data more effectively.
						<br />
						<span class="font-medium">DATA SUBJECT</span> is any living
						individual who is the subject of Personal Data.
						<br />
						<span class="font-medium">THE USER</span> is the individual using
						our Service. The User corresponds to the Data Subject, who is the
						subject of Personal Data.
						<br />
						<br />
						<h4 className="font-medium">3. Information Collection and Use</h4>
						We collect several different types of information for various
						purposes to provide and improve our Service to you.
						<br />
						<br />
						<h4 className="font-medium">4. Types of Data Collected</h4>
						<h5 className="font-medium">Personal Data</h5>
						While using our Service, we may ask you to provide us with certain
						personally identifiable information that can be used to contact or
						identify you (“Personal Data”). Personally identifiable information
						may include, but is not limited to:
						<ol className="list-decimal ml-30">
							<li>Email address</li>
							<li>First name and last name</li>
							<li>Phone number</li>
							<li>Address, Country, State, Province, ZIP/Postal code, City</li>
							<li>Cookies and Usage Data</li>
						</ol>
						<br />
						We may use your Personal Data to contact you with newsletters,
						marketing or promotional materials and other information that may be
						of interest to you. You may opt out of receiving any, or all, of
						these communications from us by following the unsubscribe link.
						<br />
						<br />
						<h5 className="font-medium">Usage Data</h5>
						We may also collect information that your browser sends whenever you
						visit our Service or when you access Service by or through any
						device (“Usage Data”).
						<br />
						This Usage Data may include information such as your computer’s
						Internet Protocol address (e.g. IP address), browser type, browser
						version, the pages of our Service that you visit, the time and date
						of your visit, the time spent on those pages, unique device
						identifiers and other diagnostic data.
						<br />
						When you access Service with a device, this Usage Data may include
						information such as the type of device you use, your device unique
						ID, the IP address of your device, your device operating system, the
						type of Internet browser you use, unique device identifiers and
						other diagnostic data.
						<br />
						Tracking Cookies Data
						<br />
						We use cookies and similar tracking technologies to track the
						activity on our Service and we hold certain information.
						<br />
						Cookies are files with a small amount of data which may include an
						anonymous unique identifier. Cookies are sent to your browser from a
						website and stored on your device. Other tracking technologies are
						also used such as beacons, tags and scripts to collect and track
						information and to improve and analyze our Service.
						<br />
						You can instruct your browser to refuse all cookies or to indicate
						when a cookie is being sent. However, if you do not accept cookies,
						you may not be able to use some portions of our Service.
						<br />
						Examples of Cookies we use:
						<ol className="list-decimal ml-30">
							<li>
								<span class="font-medium">Session Cookies:</span> We use Session
								Cookies to operate our Service.
							</li>
							<li>
								<span class="font-medium">Preference Cookies:</span> We use
								Preference Cookies to remember your preferences and various
								settings.
							</li>
							<li>
								<span class="font-medium">Security Cookies:</span> We use
								Security Cookies for security purposes.
							</li>
							<li>
								<span class="font-medium">Advertising Cookies:</span>{' '}
								Advertising Cookies are used to serve you with advertisements
								that may be relevant to you and your interests.
							</li>
						</ol>
						<br />
						<br />
						<h5 className="font-medium">Other Data</h5>
						While using our Service, we may also collect the following
						information: sex, age, date of birth, place of birth, passport
						details, citizenship, registration at place of residence and actual
						address, telephone number (work, mobile), details of documents on
						education, qualification, professional training, employment
						agreements,{' '}
						<ExternalLink link="https://policymaker.io/non-disclosure-agreement/">
							NDA agreements
						</ExternalLink>
						, information on bonuses and compensation, information on marital
						status, family members, social security (or other taxpayer
						identification) number, office location and other data.
						<br />
						<br />
						<h4 className="font-medium">5. Use of Data</h4>
						Opposite LLP uses the collected data for various purposes:
						<ol className="list-decimal ml-30">
							<li>to provide and maintain our Service;</li>
							<li>to notify you about changes to our Service;</li>
							<li>
								to allow you to participate in interactive features of our
								Service when you choose to do so;
							</li>
							<li>to provide customer support;</li>
							<li>
								to gather analysis or valuable information so that we can
								improve our Service;
							</li>
							<li>to monitor the usage of our Service;</li>
							<li>to detect, prevent and address technical issues;</li>
							<li>to fulfil any other purpose for which you provide it;</li>
							<li>
								to carry out our obligations and enforce our rights arising from
								any contracts entered into between you and us, including for
								billing and collection;
							</li>
							<li>
								to provide you with notices about your account and/or
								subscription, including expiration and renewal notices,
								email-instructions, etc.;
							</li>
							<li>
								to provide you with news, special offers and general information
								about other goods, services and events which we offer that are
								similar to those that you have already purchased or enquired
								about unless you have opted not to receive such information;
							</li>
							<li>
								in any other way we may describe when you provide the
								information;
							</li>
							<li>for any other purpose with your consent.</li>
						</ol>
						<br />
						<br />
						<h4 className="font-medium">6. Retention of Data</h4>
						We will retain your Personal Data only for as long as is necessary
						for the purposes set out in this Privacy Policy. We will retain and
						use your Personal Data to the extent necessary to comply with our
						legal obligations (for example, if we are required to retain your
						data to comply with applicable laws), resolve disputes, and enforce
						our legal agreements and policies.
						<br />
						We will also retain Usage Data for internal analysis purposes. Usage
						Data is generally retained for a shorter period, except when this
						data is used to strengthen the security or to improve the
						functionality of our Service, or we are legally obligated to retain
						this data for longer time periods.
						<br />
						<br />
						<h4 className="font-medium">7. Transfer of Data</h4>
						Your information, including Personal Data, may be transferred to -
						and maintained on - computers located outside of your state,
						province, country or other governmental jurisdiction where the data
						protection laws may differ from those of your jurisdiction.
						<br />
						If you are located outside India and choose to provide information
						to us, please note that we transfer the data, including Personal
						Data, to India and process it there.
						<br />
						Your consent to this Privacy Policy followed by your submission of
						such information represents your agreement to that transfer.
						<br />
						Opposite LLP will take all the steps reasonably necessary to ensure
						that your data is treated securely and in accordance with this
						Privacy Policy and no transfer of your Personal Data will take place
						to an organisation or a country unless there are adequate controls
						in place including the security of your data and other personal
						information.
						<br />
						<br />
						<h4 className="font-medium">8. Disclosure of Data</h4>
						We may disclose personal information that we collect, or you
						provide:
						<ol className="list-decimal ml-30">
							<li className="mb-10">
								<span class="font-medium">Disclosure for Law Enforcement.</span>{' '}
								<br />
								Under certain circumstances, we may be required to disclose your
								Personal Data if required to do so by law or in response to
								valid requests by public authorities.
							</li>
							<li className="mb-10">
								<span class="font-medium">Business Transaction.</span> <br />
								If we or our subsidiaries are involved in a merger, acquisition
								or asset sale, your Personal Data may be transferred.
							</li>
							<li>
								<span class="font-medium">
									Other cases. We may disclose your information also:
								</span>
								<br />
								<ol className="list-decimal ml-30">
									<li>to our subsidiaries and affiliates;</li>
									<li>
										to contractors, service providers, and other third parties
										we use to support our business;
									</li>
									<li>to fulfill the purpose for which you provide it;</li>
									<li>
										for the purpose of including your company’s logo on our
										website;
									</li>
									<li>
										for any other purpose disclosed by us when you provide the
										information;
									</li>
									<li>with your consent in any other cases;</li>
									<li>
										if we believe disclosure is necessary or appropriate to
										protect the rights, property, or safety of the Company, our
										customers, or others.
									</li>
								</ol>
							</li>
						</ol>
						<br />
						<br />
						<h4 className="font-medium">9. Security of Data</h4>
						The security of your data is important to us but remember that no
						method of transmission over the Internet or method of electronic
						storage is 100% secure. While we strive to use commercially
						acceptable means to protect your Personal Data, we cannot guarantee
						its absolute security.
						<br />
						<br />
						<h4 className="font-medium">
							10. Your Data Protection Rights Under General Data Protection
							Regulation (GDPR)
						</h4>
						If you are a resident of the European Union (EU) and European
						Economic Area (EEA), you have certain data protection rights,
						covered by GDPR. <br />
						We aim to take reasonable steps to allow you to correct, amend,
						delete, or limit the use of your Personal Data. <br />
						If you wish to be informed what Personal Data we hold about you and
						if you want it to be removed from our systems, please email us at{' '}
						<ExternalLink link="mailto:911@oppositehq.com">
							911@oppositehq.com
						</ExternalLink>
						. <br />
						In certain circumstances, you have the following data protection
						rights: <br />
						<ol className="list-decimal ml-30">
							<li>
								the right to access, update or to delete the information we have
								on you;
							</li>
							<li>
								the right of rectification. You have the right to have your
								information rectified if that information is inaccurate or
								incomplete;
							</li>
							<li>
								the right to object. You have the right to object to our
								processing of your Personal Data;
							</li>
							<li>
								the right of restriction. You have the right to request that we
								restrict the processing of your personal information;
							</li>
							<li>
								the right to data portability. You have the right to be provided
								with a copy of your Personal Data in a structured,
								machine-readable and commonly used format;
							</li>
							<li>
								the right to withdraw consent. You also have the right to
								withdraw your consent at any time where we rely on your consent
								to process your personal information;
							</li>
						</ol>
						Please note that we may ask you to verify your identity before
						responding to such requests. Please note, we may not able to provide
						Service without some necessary data.
						<br />
						You have the right to complain to a Data Protection Authority about
						our collection and use of your Personal Data. For more information,
						please contact your local data protection authority in the European
						Economic Area (EEA).
						<br />
						<br />
						<h4 className="font-medium">
							11. Your Data Protection Rights under the California Privacy
							Protection Act (CalOPPA)
						</h4>
						CalOPPA is the first state law in the nation to require commercial
						websites and online services to post a privacy policy. The law’s
						reach stretches well beyond California to require a person or
						company in the United States (and conceivable the world) that
						operates websites collecting personally identifiable information
						from California consumers to post a conspicuous privacy policy on
						its website stating exactly the information being collected and
						those individuals with whom it is being shared, and to comply with
						this policy. According to CalOPPA we agree to the following:
						<ol className="list-decimal ml-30">
							<li>users can visit our site anonymously;</li>
							<li>
								our Privacy Policy link includes the word “Privacy”, and can
								easily be found on the home page of our website;
							</li>
							<li>
								users will be notified of any privacy policy changes on our
								Privacy Policy Page;
							</li>
							<li>
								users are able to change their personal information by emailing
								us at{' '}
								<ExternalLink link="mailto:911@oppositehq.com">
									911@oppositehq.com
								</ExternalLink>
								.
							</li>
						</ol>
						<br />
						Our Policy on “Do Not Track” Signals: <br />
						We honor Do Not Track signals and do not track, plant cookies, or
						use advertising when a Do Not Track browser mechanism is in place.
						Do Not Track is a preference you can set in your web browser to
						inform websites that you do not want to be tracked.
						<br />
						You can enable or disable Do Not Track by visiting the Preferences
						or Settings page of your web browser.
						<br />
						<br />
						<h4 className="font-medium">
							12. Your Data Protection Rights under the California Consumer
							Privacy Act (CCPA)
						</h4>
						If you are a California resident, you are entitled to learn what
						data we collect about you, ask to delete your data and not to sell
						(share) it. To exercise your data protection rights, you can make
						certain requests and ask us:
						<ol className="list-decimal ml-30">
							<li className="mb-10">
								<span class="font-medium">
									What personal information we have about you. If you make this
									request, we will return to you:
								</span>
								<ol className="list-decimal ml-30">
									<li>
										The categories of personal information we have collected
										about you.
									</li>
									<li>
										The categories of sources from which we collect your
										personal information.
									</li>
									<li>
										The business or commercial purpose for collecting or selling
										your personal information.
									</li>
									<li>
										The categories of third parties with whom we share personal
										information.
									</li>
									<li>
										The specific pieces of personal information we have
										collected about you.
									</li>
									<li>
										A list of categories of personal information that we have
										sold, along with the category of any other company we sold
										it to. If we have not sold your personal information, we
										will inform you of that fact.
									</li>
									<li>
										A list of categories of personal information that we have
										disclosed for a business purpose, along with the category of
										any other company we shared it with.
									</li>
								</ol>
								<br />
								Please note, you are entitled to ask us to provide you with this
								information up to two times in a rolling twelve-month period.
								When you make this request, the information provided may be
								limited to the personal information we collected about you in
								the previous 12 months.
							</li>
							<li className="mb-10">
								<span class="font-medium">
									To delete your personal information. If you make this request,
									we will delete the personal information we hold about you as
									of the date of your request from our records and direct any
									service providers to do the same. In some cases, deletion may
									be accomplished through de-identification of the information.
									If you choose to delete your personal information, you may not
									be able to use certain functions that require your personal
									information to operate.
								</span>
							</li>
							<li>
								<span class="font-medium">
									To stop selling your personal information. We don’t sell or
									rent your personal information to any third parties for any
									purpose. We do not sell your personal information for monetary
									consideration. However, under some circumstances, a transfer
									of personal information to a third party, or within our family
									of companies, without monetary consideration may be considered
									a “sale” under California law. You are the only owner of your
									Personal Data and can request disclosure or deletion at any
									time.
								</span>
							</li>
						</ol>
						<br />
						If you submit a request to stop selling your personal information,
						we will stop making such transfers.
						<br />
						Please note, if you ask us to delete or stop selling your data, it
						may impact your experience with us, and you may not be able to
						participate in certain programs or membership services which require
						the usage of your personal information to function. But in no
						circumstances, we will discriminate against you for exercising your
						rights.
						<br />
						To exercise your California data protection rights described above,
						please send your request(s) by email:{' '}
						<ExternalLink link="mailto:911@oppositehq.com">
							911@oppositehq.com
						</ExternalLink>
						.
						<br />
						Your data protection rights, described above, are covered by the
						CCPA, short for the California Consumer Privacy Act. To find out
						more, visit the official California Legislative Information website.
						The CCPA took effect on 01/01/2020.
						<br />
						<br />
						<h4 className="font-medium">13. Service Providers</h4>
						We may employ third party companies and individuals to facilitate
						our Service (“Service Providers”), provide Service on our behalf,
						perform Service-related services or assist us in analysing how our
						Service is used.
						<br />
						These third parties have access to your Personal Data only to
						perform these tasks on our behalf and are obligated not to disclose
						or use it for any other purpose.
						<br />
						<br />
						<h4 className="font-medium">14. Analytics</h4>
						We may use third-party Service Providers to monitor and analyze the
						use of our Service.
						<br />
						<br />
						<h4 className="font-medium">15. CI/CD tools</h4>
						We may use third-party Service Providers to automate the development
						process of our Service.
						<br />
						<br />
						<h4 className="font-medium">16. Behavioral Remarketing</h4>
						We may use remarketing services to advertise on third party websites
						to you after you visited our Service. We and our third-party vendors
						use cookies to inform, optimise and serve ads based on your past
						visits to our Service.
						<br />
						<br />
						<h4 className="font-medium">17. Links to Other Sites</h4>
						Our Service may contain links to other sites that are not operated
						by us. If you click a third party link, you will be directed to that
						third party’s site. We strongly advise you to review the Privacy
						Policy of every site you visit.
						<br />
						We have no control over and assume no responsibility for the
						content, privacy policies or practices of any third party sites or
						services.
						<br />
						<br />
						<h4 className="font-medium">18. Children’s Privacy</h4>
						Our Services are not intended for use by children under the age of
						18 (“<span class="font-medium">Child</span>” or “
						<span class="font-medium">Children</span>”). <br />
						We do not knowingly collect personally identifiable information from
						Children under 18. If you become aware that a Child has provided us
						with Personal Data, please contact us. If we become aware that we
						have collected Personal Data from Children without verification of
						parental consent, we take steps to remove that information from our
						servers.
						<br />
						<br />
						<h4 className="font-medium">19. Changes to This Privacy Policy</h4>
						We may update our Privacy Policy from time to time. We will notify
						you of any changes by posting the new Privacy Policy on this page.
						<br />
						We will let you know via email and/or a prominent notice on our
						Service, prior to the change becoming effective and update
						“effective date” at the top of this Privacy Policy.
						<br />
						You are advised to review this Privacy Policy periodically for any
						changes. Changes to this Privacy Policy are effective when they are
						posted on this page.
						<br />
						<br />
						<h4 className="font-medium">20. Contact Us</h4>
						If you have any questions about this Privacy Policy, please contact
						us by email:{' '}
						<ExternalLink link="mailto:911@oppositehq.com">
							911@oppositehq.com
						</ExternalLink>
						.
						<br />
					</div>
				</section>
			</div>
		</Layout>
	)
}

IndexPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			homePage: PropTypes.shape({
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}

export const query = graphql`
	{
		strapi {
			homePage {
				footer_headline
				footer_link_url
				footer_link_text
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
		}
	}
`

export default IndexPage
